<template>
  <div class="container">

    <div class="header">
      <img src="../assets/logo.png" class="logo" />
      <span class="header-word" style="color: #5C53CF; font-weight: 600;font-size: 23px;">兔谱App</span>
      <span class="header-word">网页版</span>
      <span class="header-word">加入兔谱</span>
    </div>

    <div class="mid-contaner">
      <div class="img-contaner">
        <div style="margin-top: 100px;">
          <img src="../assets/IMG_7739 2.png" class="personIcon">
        </div>
        <div class="imgStyle">
          <img src="@/assets/decorate/badminton.png" width="120px" class="badmintonIcon">
          <img src="@/assets/phone3.png" class="phoneIcon" style="margin-top: -70px;">
          <img src="@/assets/tag4.png" class="tagIcon" style="right: 100px; position: relative; bottom: 150px;">
        </div>
        <div class="imgStyle">
          <img src="@/assets/themeword.png" width="180px" height="100px" style="margin-left: 100px;">
          <img src="@/assets/phone1.png" class="phoneIcon" style="margin-top: 34px;">
          <img src="@/assets/tag1.png" class="tagIcon" style="right: 40px; position: relative; bottom: 386px;">
        </div>
        <div class="imgStyle">
          <img src="@/assets/phone5.png" class="phoneIcon" style="margin-top: 96px;">
          <img src="@/assets/tag5.png" class="tagIcon" style="right: 42px;position: relative;bottom: 104px;">
        </div>
        <div class="imgStyle">
          <img src="@/assets/phone4.png" class="phoneIcon" style="margin-top: 63px;">
          <img src="@/assets/tag2.png" class="tagIcon" style="position: relative;bottom: 169px;left: 30px;">
        </div>
        <div class="imgStyle">
          <img src="@/assets/decorate/cat.png" width="100px" height="100px" style="margin-top: -55px;margin-left: 60px;">
          <img src="@/assets/phone2.png" class="phoneIcon" style="margin-top: -123px;">
          <img src="@/assets/tag3.png" class="tagIcon" style="position: relative;bottom: 325px;left: 81px;">
        </div>
        <div>
          <img src="@/assets/IMG_7740 1.png" class="personIcon">
        </div>
      </div>

      <div class="code-contaner">
        <img src="@/assets/code.png" class="codeIcon1">
        <div class="codeTxt">
          <div>手机扫码</div>
          <div>下载APP</div>
          <img src="@/assets/logo.png" class="codeLogo" style="margin-top:5px">
        </div>
        
      </div>
    </div>

    <div id="area1" class="mid-contaner2" :class="{ animated: page0End, 'bounceInDown': page0End }">
      <div class="product-intro">
          <img src="@/assets/product-txt.png" width="110px" height="30px">
        <div class="product-txt">
          <div>免谱是一个本地社群的综合社交平台，致力于通过共同的兴趣和价值观将用户聚集在一起，促进深度连接与信任。 </div>
          <div>在这里，用户不仅可以在线互动，还可以通过线下活动这一载体，建立更有意义的社交关系。 </div>
          <div>同时，为品牌和用户建立直接对话，将品牌以更贴近用户生活的方式参与其中，实现品牌价值的精准传达与深度渗透。</div>
        </div>
      </div>
      <div class="product-img">
        <img src="@/assets/product1.png">
        <img src="@/assets/product2.png">
        <img src="@/assets/product3.png">
        <img src="@/assets/product4.png">
      </div>
    </div>

    <div id="area2" class="mid-contaner3":class="{ animated: page1End, 'zoomIn': page1End }">
      <div class="value-txt">
        <div class="vatxt1">核心价值</div>
        <div class="vatxt2">通过建立深度的社交连接，促进用户之间的信任与互动同时帮助品牌与用户建立更加亲密的关系</div>
      </div>
      <div class="value-img">
        <img src="@/assets/valueimg.png" width="660px" height="420px">
      </div>
    </div>

    <div id="area3" class="mid-contaner3" style="background-color: #FAFAFA;":class="{ animated: page2End, 'flipInX': page2End }">
      <div style="margin-top: 30px;">
        <img src="@/assets/valueimg2.png" width="460px" height="380px">
      </div>
      <div class="value-txt2">
        <div class="vatxt1">平台宗旨</div>
        <div class="vatxt2">致力于打破社交、圈层、行业壁垒，让用户能真正实现破圈社交，为用户和社会带来更多的价值和能量</div>
      </div>
    </div>

    <div id="area4" class="mid-contaner4" :class="{ animated: page3End, 'slideInLeft': page3End }">
      <div class="value-txt">
        <div class="companytxtimg">
          <img src="@/assets/comanytxt.png">
        </div>
        <div class="companytxt">
          <div>成都涌泉巨浪信息技术服务有限公司成立于2023年，总部位于四川成都，是一家专注于创新的数字科技综合运营公司。公司业务共分为三大板块：开发数据类、互联网传媒类、综合实业类。
          </div>
          <div>
          我们致力于提供优质、高效、专业的服务，以“让社交变得更有价值”为使命，希望为社会的数字化进程持续添砖加瓦。
          </div>
        </div>
        
      </div>
      <div class="value-img">
        <img src="@/assets/companyimg.png" width="660px" height="420px">
      </div>
    </div>

    <div class="footer">
      <div style="margin-top:50px;">
        <img src="@/assets/join.png" width="200px" height="65px">
      </div>
      <div style="display:flex;flex-direction: column;">
        <div class="icontxt">
          <div class="icontxt2">
            <img src="@/assets/mail.png" width="30px" height="30px">
            <span style="margin-left:10px;" >boryzhuo@163.com</span>
          </div>
          <div class="icontxt2">
            <img src="@/assets/phoneicon.png"width="30px" height="30px" style="margin-left:10px;">
            <span style="margin-left:10px;" >4009607828</span>
          </div>
        </div>

        <div class="icontxt2" style="margin-top:20px;">
          <img src="@/assets/location.png"width="30px" height="30px">
          <span style="margin-left:10px;">成都市成华区祥和里街82号玉双岛文创园区413</span>
        </div>
      </div>

      <div class="downloadcode">
        <div style="">
          <img src="@/assets/code.png" width="90px" height="90px" >
          <div>扫一扫IOS下载</div>
        </div>

        <div style="margin-left:50px;">
          <img src="@/assets/code.png" width="90px" height="90px">
          <div>扫一扫安卓下载</div>
        </div>
      </div>
    </div>

    <div class="recordNum">
      <div>
        公安局备案号：<a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023012419号-1</a>
      </div>
        
      </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'HomeView',
  data() {
    return {
      page0End: false,  // 控制区块一动画结束标志
      page1End: false,  // 控制区块二动画结束标志
      page2End: false,  // 控制区块三动画结束标志
      page3End: false,  // 控制区块四动画结束标志
    };
  },
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY;

      // 判断每个区块的滚动位置并触发动画
      if (scrollTop >= this.getOffsetTop('#area1') - 400 && !this.area1End) {
        this.triggerAnimation('#area1', 'bounceInDown');
        this.page0End = true;
      }
      if (scrollTop >= this.getOffsetTop('#area2') - 400 && !this.area2End) {
        this.triggerAnimation('#area2', 'zoomIn');
        this.page1End = true;
      }
      if (scrollTop >= this.getOffsetTop('#area3') - 400 && !this.area3End) {
        this.triggerAnimation('#area3', 'flipInX');
        this.page2End = true;
      }
      if (scrollTop >= this.getOffsetTop('#area4') - 400 && !this.area4End) {
        this.triggerAnimation('#area4', 'slideInLeft');
        this.page3End = true;
      }
    },
    getOffsetTop(selector) {
      const element = document.querySelector(selector);
      return element ? element.offsetTop : 0;
    },
    triggerAnimation(elementId, animationClass) {
      const element = document.querySelector(elementId);
      if (element) {
        element.classList.remove('hidden'); // 移除隐藏样式
        element.style.visibility = "visible"; // 设置可见性
        element.classList.add(animationClass); // 添加新的动画类
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // 初始化时检查位置
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>
<style scoped>
  .container {
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    width: 60px;
    height: 60px;
    margin-left: 40px;
    margin-right: -40px;
  }

  .header-word {
    margin-left: 50px;
    font-size: 17px;
  }

  .mid-contaner{
    background-image: url("@/assets/decorate/backline.png");
  }
  .img-contaner{
    display: flex;
    margin-top: 40px;
  }
  .imgStyle{
    display: flex;
    flex-wrap: wrap;
    width: 215px;
  }
  .personIcon{
    height: 400px;
  }
  .phoneIcon{
    height: 450px;
  }
  .badmintonIcon{
    margin-top: -30px;
    margin-bottom: 30px;
    margin-left: 100px;
  }
  .tagIcon{
    width: 300px;
    height: 80px;
  }
  .code-contaner{
    width: 237px;
    height: 168px;
    border: 4px solid rgba(1, 42, 166, 0.90);
    border-radius: 5%;
    display: flex;
    margin-left: 800px;
    margin-bottom: 49px;
    margin-top: -40px;
  }
  .codeIcon1{
    width: 128px;
    height: 125px;
    margin-top: 18px;
    margin-left: 5px;
  }
  .codeLogo{
    width: 70px;
    height: 70px;
  }
  .codeTxt{
    font-weight: 600;
    font-size: 20px;
    color: rgba(0,19,76,0.9);
    margin-top: 18px;
    margin-left: 8px;
  }
  
  
  
  .footer{
    border-bottom: 1px solid rgba(0,19,76,0.5);
    display: flex;
    align-items: center;
    padding-left: 300px;
    padding-bottom: 20px;
  }
  .icontxt{
    display: flex;
    margin-top: 50px;
  }
  .icontxt2{
    display: flex;
    align-items: center;
  }
  .downloadcode{
    display: flex;
    margin-left: 200px;
    margin-top: 30px;
  }
  .mid-contaner2{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #FAFAFA;
    width: 100%;
    height: 700px;
   
  }
  .product-intro{
     margin-top: 10px;
  }
  .product-txt{
    font-family: Lantinghei SC, Lantinghei SC;
    font-weight: 200;
    font-size: 20px;
    color: rgba(0,19,76,0.9);
    line-height: 35px;
    margin-top: 10px;
  }
  .product-img{
    margin-top: 10px;
  }
  .product-img img{
    margin-right: 70px;
  }
  .mid-contaner3{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    
  }
  .vatxt1{
    font-family: Lantinghei SC, Lantinghei SC;
    font-weight: 600;
    font-size: 37px;
    color: #8098F0;
    width: 200px;
    text-align: left;
    margin-top: 50px;
  }
  .vatxt2{
    width: 400px;
    font-family: Lantinghei SC, Lantinghei SC;
    font-weight: 200;
    font-size: 28px;
    color: rgba(0,19,76,0.9);
    text-align: left;
    line-height: 40px;
    margin-top: 80px;
  }
  .value-img{
    width: 700px;
    height: 420px;
    margin-top: 20px;
  }
  .value-txt2{
    margin-right: 50px;
  }
  .mid-contaner4{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    
    background-image: url("@/assets/companybc.png");
  }
  .companytxtimg{
    margin-top: 60px;
    text-align: left;
  }
  .companytxt{
    font-family: Lantinghei SC, Lantinghei SC;
    font-weight: 200;
    font-size: 20px;
    color: rgba(0,19,76,0.9);
    width: 500px;
    text-align: left;
    margin-top: 20px;
    line-height: 35px;
  }
  .recordNum{
    display: flex;
    justify-content: center;
    font-family: Lantinghei SC, Lantinghei SC;
    font-weight: 200;
    font-size: 16px;
    color: rgba(0,19,76,0.9);
    padding-top: 20px;
    padding-bottom: 20px;
  }
</style>
